<template>
  <div class="mobile-wrap center">
    <div class="logo">
      <img :src="require('@/assets/image/ozb1.png')" alt="">
    </div>
    <div class="banner">
      <img :src="require('@/assets/image/bj.png')" alt="">
    </div>
    <div class="intrs" @click="register('by');">
      <img :src="require('@/assets/image/ljd.png')" alt="">
    </div>
    <div class="buttons">
      <div class="row">
        <a href="javascript:void(0);" @click="register('by');"></a>
      </div>
      <div class="row">
        <a href="javascript:void(0);" @click="register('by');"></a>
        <button @click="register('byApp');"></button>
      </div>
    </div>
    <div class="lines">
      <div class="list">
        <ul>
          <li>
            <div class="imgs fl"><img :src="require('@/assets/image/xk1.png')" alt></div>
            <div class="text fl">
              <div class="txts fl">
                <p>优享站点</p>
                <h3>星空登录入口</h3>
                <h5>19ms/s</h5>
              </div>
              <div class="btns fr">
                <a class="btnss" data-url="" href="javascript:void(0);" @click="register('by');">立即试玩</a>
              </div>
            </div>
          </li>
          <li>
            <div class="imgs fl"><img :src="require('@/assets/image/xk1.png')" alt></div>
            <div class="text fl">
              <div class="txts fl">
                <p>优享站点</p>
                <h3>星空APP下载</h3>
                <h5>19ms/s</h5>
              </div>
              <div class="btns fr">
                <a class="btnss" data-url="" href="javascript:void(0);" @click="register('byApp');">立即下载</a>
              </div>
            </div>
          </li>
          <li>
            <div class="imgs fl"><img :src="require('@/assets/image/ky.png')" alt></div>
            <div class="text fl">
              <div class="txts fl">
                <p>优享站点</p>
                <h3>开云登录入口</h3>
                <h5>19ms/s</h5>
              </div>
              <div class="btns fr">
                <a class="btnss" data-url="" href="javascript:void(0);" @click="register('ky');">立即试玩</a>
              </div>
            </div>
          </li>
          <li>
            <div class="imgs fl"><img :src="require('@/assets/image/ky.png')" alt></div>
            <div class="text fl">
              <div class="txts fl">
                <p>优享站点</p>
                <h3>开云APP下载</h3>
                <h5>19ms/s</h5>
              </div>
              <div class="btns fr">
                <a class="btnss" data-url="" href="javascript:void(0);" @click="register('kyApp');">立即下载</a>
              </div>
            </div>
          </li>
          <li>
            <div class="imgs fl"><img :src="require('@/assets/image/jy.png')" alt></div>
            <div class="text fl">
              <div class="txts fl">
                <p>优享站点</p>
                <h3>9游登录入口</h3>
                <h5>19ms/s</h5>
              </div>
              <div class="btns fr">
                <a class="btnss" data-url="" href="javascript:void(0);" @click="register('jy1');">立即试玩</a>
              </div>
            </div>
          </li>
          <li>
            <div class="imgs fl"><img :src="require('@/assets/image/jy.png')" alt></div>
            <div class="text fl">
              <div class="txts fl">
                <p>优享站点</p>
                <h3>9游APP下载</h3>
                <h5>19ms/s</h5>
              </div>
              <div class="btns fr">
                <a class="btnss" data-url="" href="javascript:void(0);" @click="register('jyApp');">立即下载</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="label">
      <img :src="require('@/assets/image/a77810_680x100.png')" alt="">
    </div>
    <div class="type">
      <img :src="require('@/assets/image/e0ef87_750x682.png')" alt="">
    </div>
    <div class="cooperation">
      <img src="https://ziyuan-css.cdn.bcebos.com/34/2/f293f7_750x688.bin" alt="">
    </div>
    <div class="fix-down" @click="register('xkApp');">
      <img :src="require('@/assets/image/xkf.png')" alt="">
      <button></button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  setup() {
    var xkcode = 51346931;
    var bycode = 8402241;
    var jycode = 93506464;
    var kycode = 90728003;

    var PC1 = link.XKPC.trim() + "/user/register/?agent_code=" + xkcode;
    var PC2 = link.XKPC2.trim() + "/user/register/?agent_code=" + xkcode;
    var H51 = link.XKH5.trim() + "/user/register/?agent_code=" + xkcode;
    var H52 = link.XKH52.trim() + "/user/register/?agent_code=" + xkcode;
    var APP = link.XKAPP.trim() + "/?agent_code=" + xkcode;

    var BYH5 = link.BYH5.trim() + "/entry/register/?i_code=" + bycode;
    var BYPC = link.BYPC.trim() + "/register/?i_code=" + bycode;
    var BYAPP = link.BYAPP.trim() + "/?i_code=" + bycode;

    var JYPC = link.JYPC.trim() + "/register/?i_code=" + jycode;
    var JYPC2 = link.JYPC2.trim() + "/register/?i_code=" + jycode;
    var JYH5 = link.JYH5.trim() + "/entry/register/?i_code=" + jycode;
    var JYH52 = link.JYH52.trim() + "/entry/register/?i_code=" + jycode;
    var JYAPP = link.JYAPP.trim() + "/?i_code=" + jycode;

    var KYH5 = link.KYH5.trim() + "/entry/register/?i_code=" + kycode;
    var KYPC = link.KYPC.trim() + "/register/?i_code=" + kycode;
    var KYAPP = link.KYAPP.trim() + "/?i_code=" + kycode;

    function register(tag) {
      switch (tag) {
        case "xk1":
          xk_open_pc_or_m(PC1, H51);
          break;
        case "xk2":
          xk_open_pc_or_m(PC2, H52);
          break;
        case "xkApp":
          window.open(APP);
          break;
        case "by":
          xk_open_pc_or_m(BYPC, BYH5);
          break;
        case "byApp":
          window.open(BYAPP);
          break;
        case "jy1":
          xk_open_pc_or_m(JYPC, JYH5);
          break;
        case "jy2":
          xk_open_pc_or_m(JYPC2, JYH52);
          break;
        case "jyApp":
          window.open(JYAPP);
          break;
        case "ky":
          xk_open_pc_or_m(KYPC, KYH5);
          break;
        case "kyApp":
          window.open(KYAPP);
          break;
      }
    }

    function isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function xk_open_pc_or_m(pc_url, m_url) {
      if (isMobile()) {
        window.open(m_url);
      } else {
        window.open(pc_url);
      }
    }

    //禁用右键（防止右键查看源代码）
    window.oncontextmenu = function () {
      return false;
    }
    //禁止任何键盘敲击事件（防止F12和shift+ctrl+i调起开发者工具）
    window.onkeydown = window.onkeyup = window.onkeypress = function () {
      window.event.returnValue = false;
      return false;
    }
    // disablePictureInPicture = "true"
    window.onload = function () {
      document.onkeydown = function () {
        var e = window.event || arguments[0];
        //屏蔽F12
        if (e.keyCode == 123) {
          return false;
          //屏蔽Ctrl+Shift+I
        } else if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 73)) {
          return false;
          //屏蔽Shift+F10
        } else if ((e.shiftKey) && (e.keyCode == 121)) {
          return false;
          //屏蔽Ctrl+U
        } else if ((e.ctrlKey) && (e.keyCode == 85)) {
          return false;
        }
      }
      document.oncontextmenu = function () {
        return false;
      }
      if (window.event && window.event.keyCode == 123) {
        event.keyCode = 0;
        event.returnValue = false;
      }
      if (window.event && window.event.keyCode == 13) {
        window.event.keyCode = 505;
      }
      if (window.event && window.event.keyCode == 8) {
        window.event.returnValue = false;
      }
      if (window.event) {
        event = window.event;
      }
      try {
        var the = event.srcElement;
        if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")) {
          return false;
        }
        return true;
      } catch (e) {
        return false;
      }
    }
    //禁止鼠标右击
    document.oncontextmenu = function () {
      event.returnValue = false;
    };
    //禁用开发者工具F12
    document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
      let e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 123) {
        e.returnValue = false;
        return false;
      }
    };
    let userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") > -1) {
      let checkStatus;
      let devtools = /./;
      devtools.toString = function () {
        checkStatus = "on";
      };
      setInterval(function () {
        checkStatus = "off";
        console.log(devtools);
        console.log(checkStatus);
        console.clear();
        if (checkStatus === "on") {
          let target = "";
          try {
            window.open("about:blank", (target = "_self"));
          } catch (err) {
            let a = document.createElement("button");
            a.onclick = function () {
              window.open("about:blank", (target = "_self"));
            };
            a.click();
          }
        }
      }, 200);
    } else {
      //禁用控制台
      let ConsoleManager = {
        onOpen: function () {
          alert("Console is opened");
        },
        onClose: function () {
          alert("Console is closed");
        },
        init: function () {
          let self = this;
          let x = document.createElement("div");
          let isOpening = false,
            isOpened = false;
          Object.defineProperty(x, "id", {
            get: function () {
              if (!isOpening) {
                self.onOpen();
                isOpening = true;
              }
              isOpened = true;
              return true;
            }
          });
          setInterval(function () {
            isOpened = false;
            console.info(x);
            console.clear();
            if (!isOpened && isOpening) {
              self.onClose();
              isOpening = false;
            }
          }, 200);
        }
      };
      ConsoleManager.onOpen = function () {
        //打开控制台，跳转
        let target = "";
        try {
          window.open("about:blank", (target = "_self"));
        } catch (err) {
          let a = document.createElement("button");
          a.onclick = function () {
            window.open("about:blank", (target = "_self"));
          };
          a.click();
        }
      };
      ConsoleManager.onClose = function () {
        alert("Console is closed!!!!!");
      };
      ConsoleManager.init();
    }

    return {
      register
    };
  }
};
</script>
