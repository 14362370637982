<template>
  <WelcomHome />
</template>

<script>
import WelcomHome from './components/WelcomHome.vue'

export default {
  name: 'App',
  components: {
    WelcomHome
  }
}
</script>
<style scoped>

</style>

